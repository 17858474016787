import type { FrontContentType, HubSettings } from "@gasparigit/omnia-sdk";
import type { Component } from "vue";
import AreaAffari from "~/components/icons/AreaAffari.vue";
import AreaCommercio from "~/components/icons/AreaCommercio.vue";
import AreaDemografici from "~/components/icons/AreaDemografici.vue";
import AreaDigitale from "~/components/icons/AreaDigitale.vue";
import AreaElezioni from "~/components/icons/AreaElezioni.vue";
import AreaPersonale from "~/components/icons/AreaPersonale.vue";
import AreaPnrr from "~/components/icons/AreaPnrr.vue";
import AreaPolizia from "~/components/icons/AreaPolizia.vue";
import AreaRagioneria from "~/components/icons/AreaRagioneria.vue";
import AreaTecnico from "~/components/icons/AreaTecnico.vue";
import AreaTributi from "~/components/icons/AreaTributi.vue";
import Book from "~/components/icons/Book.vue";
import Calendar from "~/components/icons/Calendar.vue";
import Documents from "~/components/icons/Documents.vue";
import Harvest from "~/components/icons/Harvest.vue";
import Help from "~/components/icons/Help.vue";
import Magazine from "~/components/icons/Magazine.vue";
import Memoweb from "~/components/icons/Memoweb.vue";
import Record from "~/components/icons/Record.vue";
import Repubblica from "~/components/icons/Repubblica.vue";
import Website from "~/components/icons/Website.vue";

export const AREAS_MAP: Record<
  number,
  {
    icon: Component;
    color: string;
    title?: string;
  }
> = {
  1: {
    icon: AreaRagioneria,
    color: "ragioneria",
  },
  2: {
    icon: AreaTributi,
    color: "tributi",
  },
  3: {
    icon: AreaDemografici,
    color: "demografici",
  },
  4: {
    icon: AreaAffari,
    color: "affari",
  },
  5: {
    icon: AreaTecnico,
    color: "tecnico",
  },
  6: {
    icon: AreaPersonale,
    color: "personale",
  },
  7: {
    icon: AreaPolizia,
    color: "polizia",
  },
  8: {
    icon: AreaCommercio,
    color: "commercio",
  },
  9: {
    title: "Amm. Digitale",
    icon: AreaDigitale,
    color: "digitale",
  },
  10: {
    title: "PNRR",
    icon: AreaPnrr,
    color: "pnrr",
  },
  11: {
    icon: AreaElezioni,
    color: "elezioni",
  },
};

export const FRONT_TYPES_MAP: Record<
  FrontContentType,
  HubSettings["0"] & {
    icon: Component;
    hubAllCardClass?: string;
  }
> = {
  notizie: {
    type: "notizie",
    icon: Memoweb,
    order: 0,
    show_in_tabs: true,
    show_in_all: true,
  },
  pratiche: {
    type: "pratiche",
    icon: Documents,
    order: 1,
    show_in_tabs: true,
    show_in_all: true,
  },
  modelli: {
    type: "modelli",
    icon: Documents,
    order: 2,
    show_in_tabs: false,
    show_in_all: false,
  },
  quesiti: {
    type: "quesiti",
    icon: Help,
    order: 3,
    show_in_tabs: true,
    show_in_all: true,
  },
  normativa: {
    type: "normativa",
    icon: Harvest,
    order: 4,
    show_in_tabs: true,
    show_in_all: true,
  },
  portali: {
    type: "portali",
    icon: Website,
    order: 5,
    show_in_tabs: true,
    show_in_all: true,
  },
  scadenzario: {
    type: "scadenzario",
    icon: Calendar,
    order: 6,
    show_in_tabs: true,
    show_in_all: true,
  },
  ebook: {
    type: "ebook",
    icon: Book,
    order: 7,
    show_in_tabs: true,
    show_in_all: true,
    hubAllCardClass: "bg-primary-50 shadow-lg p-5",
  },
  "articoli-riviste": {
    type: "articoli-riviste",
    icon: Magazine,
    order: 8,
    show_in_tabs: true,
    show_in_all: true,
  },
  formazione: {
    type: "formazione",
    icon: Record,
    order: 9,
    show_in_tabs: true,
    show_in_all: true,
    hubAllCardClass: "bg-primary-50 shadow-lg p-5",
  },
  gazzetta: {
    type: "gazzetta",
    icon: Repubblica,
    order: 10,
    show_in_tabs: true,
    show_in_all: true,
  },
  link: {
    type: "link",
    icon: Website,
    order: 11,
    show_in_tabs: true,
    show_in_all: false,
  },
  "scadenzari-elettorali": {
    type: "scadenzari-elettorali",
    icon: Calendar,
    order: 12,
    show_in_tabs: true,
    show_in_all: true,
  },
  "scadenze-elettorali": {
    type: "scadenze-elettorali",
    icon: Calendar,
    order: 13,
    show_in_tabs: false,
    show_in_all: false,
  },
  postit: {
    type: "postit",
    icon: Memoweb,
    order: 14,
    show_in_tabs: true,
    show_in_all: true,
  },
};
